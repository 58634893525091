@mixin contrast($main-color, $main-opposed-color, $main-important-color) {
    
.table {
  --bs-table-striped-bg: #{$main-opposed-color};
  --bs-table-striped-color: #{$main-color};
  --bs-table-hover-bg: #{$main-color};
  --bs-table-hover-color: #{$main-opposed-color};
}

  .contrast {
      color: $main-color;
  }

  body {
    font-size: 14px !important;
    background-color: $main-opposed-color;
  }
  
  .bg-warning, .bg-danger, .bg-success, .bg-info, .bg-secondary {
    background-color: transparent !important;
    border: solid 2px $main-important-color !important;
    color: $main-color !important;
  }

  .bg-light {
    background-color: transparent !important;
    border: solid 2px $main-color !important;
    color: $main-color !important;

  }
  
  .table-hover > tbody > tr:hover .bg-warning, 
  .table-hover > tbody > tr:hover .bg-danger, 
  .table-hover > tbody > tr:hover .bg-success, 
  .table-hover > tbody > tr:hover .bg-info {
    color: $main-opposed-color !important;
  }

  .link-primary {
    color: $main-color;
  }
  
  button:hover svg {
    background-color: $main-color;
  }
  
  svg path {
    color: $main-important-color !important;
  }
  
  body * {
    color: $main-color !important;
    background-color: transparent;
  }
  
  .sidepanel-forsidenav {
    border-right: 2px solid $main-color;
  }
  
  .mainbar, .sidepanel-forsidenav, .main,
  .modal-content
   {
    background-color: $main-opposed-color !important;
    color: $main-color !important;
  }

  .navbar-dark .navbar-nav .nav-link,
  .mainbar *, .sidepanel-forsidenav *, .main *,
  .modal-content *
  {
    color: $main-color;
  }

  .dropdown-menu {
    background-color: $main-opposed-color;
  }

  .dropdown-divider {
    border-top: 1px solid $main-color;
    opacity: 0.8;
  }

  .nav-link:hover {
    background-color: $main-color !important;
    color: $main-opposed-color !important;
  }
  
.alert-primary,
.alert-secondary {
  background-color: transparent !important;
  border: solid 2px $main-color !important;
  color: $main-color;
}

  .alert-warning {
    background-color: transparent !important;
    border: solid 2px $main-important-color !important;
    color: $main-color;
  }
  
  .alert-danger {
    background-color: transparent !important;
    border: solid 2px $main-color !important;
  }
  
  .alert-danger .alert-heading {
    color: $main-important-color !important;
  }
  
  .alert-dark, .alert-success {
    background-color: transparent !important;
    border: solid 2px $main-color !important;
    color: $main-color;
  }
  
  .btn-outline-primary, .btn-outline-dark, .btn-outline-success,
  .btn-primary, .btn-secondary, .btn-dark, .btn-success {
    background-color: $main-opposed-color;
    border-color: $main-color;
  }
  
  .btn-danger {
    color: $main-important-color;
    background-color: $main-opposed-color;
  }
  
  .btn-danger:hover,
  .btn-danger:focus {
    color: $main-important-color;
    background-color: $main-color;
    border-color: $main-color;
  }
  
  
  .btn-check:focus + .btn-outline-primary, 
  .btn-outline-primary:focus,
  .btn-outline-primary:hover,
  .btn-outline-success:focus,
  .btn-outline-success:hover,
  .btn-outline-secondary:focus,
  .btn-outline-secondary:hover,
  .btn-outline-dark:focus,
  .btn-outline-dark:hover,
  .btn-primary:focus,
  .btn-primary:hover,
  .btn-secondary:focus,
  .btn-secondary:hover,
  .btn-success:focus,
  .btn-success:hover,
  .btn-dark:focus,
  .btn-dark:hover
  {
  
    box-shadow: none;
    background-color: $main-color;
    color: $main-opposed-color !important;
    border-color: $main-color;
  }
  
  nav.table-of-contents {
    border-right: solid 1px $main-color;
  }
  
  .table-of-contents-link {
    color: $main-color !important;
  }

  .table-striped > tbody > tr:nth-of-type(2n+1) {
    color: $main-color !important;
  }
  
  .nav-tabs {
    border-bottom: 1px solid $main-color;
  }
  
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active,
  .nav-link:hover span.toc-link-heading,
  .nav-link:hover svg.toc-link-icon,
  .nav-tabs .nav-link.active:hover {
    color: $main-opposed-color !important;
    background-color: $main-color;
    border-color: $main-opposed-color;
  }
  
  .nav-link.active {
    color: $main-color !important;
    background-color: $main-opposed-color;
  }
  
  .card {
    background-color: $main-opposed-color;
  }
  .nav {
    position: sticky;
    top: 4rem;
    background-color: $main-opposed-color;
  }
  
  .list-group-item {
    background-color: $main-opposed-color;
  }
  
  .list-group-item:hover {
    background-color: $main-opposed-color;
    outline: 2px solid $main-color;
  }
  
  .list-group-item.active {
    background-color: $main-opposed-color;
    border-color: $main-color;
  }
  
  .form-control,
  .form-control:read-only {
    background-color: $main-opposed-color;
    border-color: $main-color !important;
    border: 1px solid $main-color;
  }
  
  .form-floating > .form-control:not(:placeholder-shown) ~ label{
    background-color: transparent;
    color: $main-color;
  }
  
  .form-control:focus,
  .form-control:hover  {
    background-color: $main-opposed-color;
    outline: 2px solid $main-color;
    border: 1px solid $main-color;
  }
  
  .page-item.active .page-link {
    background-color: $main-color;
    border: 1px solid $main-color;
    color: $main-opposed-color !important;
  }
  
  .page-item .page-link {
    background-color: $main-opposed-color;
    border: 1px solid $main-color;
  }
  
  .popover {
    border: 1px solid $main-color;
  }
  .popover-header {
    background-color: $main-color;
    color: $main-opposed-color !important;
  }

  .popover-body {
    background-color: $main-opposed-color;
    color: $main-color !important;
  }
  
  
  .ant-picker-panel * {
    background-color: $main-opposed-color !important;

  }
  
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background-color: $main-color !important;
    color: $main-opposed-color !important;

  }

  .ant-picker {
    background-color: $main-opposed-color !important;
    border: 1px solid $main-color;
  }
  
  .ant-picker:hover {
    background-color: $main-opposed-color;
    border: 1px solid $main-color;
    outline: 2px solid $main-color;
  }
  
  .ant-picker-cell-inner:hover,
  .ant-picker-cell-inner:focus {
    background-color: $main-color;
    color:$main-opposed-color !important;
  }
  
  .ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: $main-color !important;
    color: $main-opposed-color !important;
  }
  
  .ant-picker-cell-today .ant-picker-cell-inner {
    border: 1px solid $main-color;
    background-color: $main-opposed-color;
    color: $main-color;
  }
  
  .accordion-button,
  .accordion-button:not(.collapsed) {
    color: $main-color !important;
    background-color: $main-opposed-color;
    border: 1px solid $main-color;
  }
  
  .accordion-button:hover,
  .accordion-button:focus {
    color: $main-color !important;
    background-color: $main-opposed-color;
    outline: 2px solid $main-color;;
    border: 1px solid $main-color;
    box-shadow: none;
  }
  
  .accordion-body {
    background-color: $main-opposed-color;
  }
  
  .dropdown-item {
    color: $main-color !important;
    background-color: $main-opposed-color !important;
  }
  
  .dropdown-menu .dropdown-item:hover,
  .dropdown-menu .dropdown-item:hover span {
    color:$main-opposed-color !important;
    background-color: $main-color !important;
  }
  
  .table th {
    color: $main-opposed-color !important;
    background-color: $main-color;
  }
  
  .table td,
  .table tr {
    border-color: $main-color;
  }
  
  .tooltip-inner {
    color: $main-opposed-color !important;
    background-color: $main-color;
  }
  
  
  .snrwb-selector {
    color: $main-important-color !important;
  }
  
  div.snrwb-react-select__control div.snrwb-react-select__value-container.snrwb-react-select__value-container--has-value div.snrwb-react-select__input-container {
    color:$main-important-color !important;
  }
  
  div.snrwb-react-select__control {
    border: 1px solid $main-color;
  }
  
  div.snrwb-react-select__control,
  div.snrwb-react-select__menu  {
    background-color: $main-opposed-color;
  }
  
  div.snrwb-react-select__control:hover,
  div.snrwb-react-select__control:focus {
    border: 1px solid $main-color;
    outline: 2px solid $main-color !important;
  }
  
  div.snrwb-react-select__control--is-snrwb-react-select__control--menu-is-open
  {
    border: 1px solid $main-color !important;
    outline: 2px solid $main-color !important;
  }
  
  
  .snrwb-react-select-option {
    background-color: $main-opposed-color !important;
    border-right: 1px solid $main-color !important;
    border-left: 1px solid $main-color !important;
  }
  
  .snrwb-react-select-option:hover,
  .snrwb-react-select-option:focus {
    background-color: $main-color !important;
    color: $main-opposed-color !important;
  }
  
  .snrwb-react-select-option:hover .badge,
  .snrwb-react-select-option:focus .badge {
    color: $main-opposed-color !important;
  }
  
  .react-loading-skeleton {
    color: $main-color;
    background-color: $main-color;
  }

  .timeline-card-content {
    color: $main-opposed-color !important;
    background-color: $main-opposed-color;
    border: 4px solid $main-color;
    box-shadow: none;
  }

  .card-description {
    background-color: $main-opposed-color !important;
  }
  
  .timeline-card-content span {
    background-color: $main-opposed-color;
    border-top: 4px solid $main-color;
    border-right: 4px solid $main-color;
    box-shadow: none;
    left: -0.9rem;
  }

  .timeline-vertical-circle .active {
    background-color: $main-color;
    box-shadow: none;
  }

  .timeline-item-title.active {
    background-color: $main-color;
    color: $main-opposed-color;
  }

  .form-check-input {
    background-color: $main-opposed-color;
  }

  .snrwb-react-select {
    &__option {
      background-color: $main-opposed-color !important;
      border-right: 1px solid $main-color !important;
      border-left: 1px solid $main-color !important;
  
      &--is-focused {
        background-color: $main-color !important;
        color: $main-opposed-color !important;
      }
      &--is-focused .badge {
        background-color: $main-color !important;
        color: $main-opposed-color !important;
      }
    }
  
    &__menu-portal {
      border: 1px solid $main-color;
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $main-color;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $main-color;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $main-color;
  }

  .ant-picker-input input::placeholder {
    color: $main-color;
  }

.toast-container .toast.fade .toast-body {
  background-color: $main-color !important;
  color: $main-opposed-color !important;
}

}
