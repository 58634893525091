@import 'contrastMixin';

.contrast {
  color: white;
}
    
.img-logotype-POWER {
  padding: 1px;
  margin: 0.1rem;
  max-width: 90%;
  height: auto;
  max-height: 95vh;
  content:url("/assets/power/FE_POWER_rgb_trans.png");
}
.activeContrastBlackYellow {
  $main-color: black;
  $main-opposed-color: yellow;
  $main-important-color: red;  

  @include contrast($main-color,$main-opposed-color,$main-important-color);

  .img-logotype-POWER {
    content:url("/assets/power/FE_POWER_BlackYellow.png");
  }
}

.activeContrastYellowBlack {
  $main-color: yellow;
  $main-opposed-color: black;
  $main-important-color: red;  

  @include contrast($main-color,$main-opposed-color,$main-important-color);

  .img-logotype-POWER {
    content:url("/assets/power/FE_POWER_YellowBlack.png");
  }
}  
.activeContrastWhiteBlack {
  $main-color: white;
  $main-opposed-color: black;
  $main-important-color: red;  

  @include contrast($main-color,$main-opposed-color,$main-important-color);

  .img-logotype-POWER {
    content:url("/assets/power/FE_POWER_WhiteBlack.png");
  }
}

.contrast-icon  {
  margin: 0.2rem;
  border: 0px solid;
  border-radius: .3rem;
}

  .contrast-default, 
  svg.contrast-default path {
    color: #61dafb !important;
    background-color: white !important;
  }
  
  .contrast-white-black,
  svg.contrast-white-black path {
    color: white !important;
    background-color: black !important;
  }
  
  .contrast-black-yellow,
  svg.contrast-black-yellow path {
    color: black !important;
    background-color: yellow !important;
  }
  
  .contrast-yellow-black,
  svg.contrast-yellow-black path {
    color: Yellow !important;
    background-color: Black !important;
  }
