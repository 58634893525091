$lighter: #373940;
/* light blue */
$dark: #282c34;
/* dark blue */
$darker: #20232a;
/* really dark blue */
$brand: #61dafb;
/* electric blue */
$brand-light: #bbeffd;
$text: #1a1a1a;
/* very dark grey / black substitute */
$subtle: #6d6d6d;
/* light grey for text */
$subtle-on-dark: #999;
$divider: #ececec;
/* very light grey */
$note: #ffe564;
/* yellow */
$error: #ff6464;
/* red */
$gray: #f7f7f7;
$white: #ffffff;
$border: #ccc;
$black: #000000;
$success: #198754;

$blue-light: #acd8ec1e;
$purple-light: #c9abeb1e;
$yellow-light: #f8cca31e;
$teal-light: #aae6be1e;
$cyan-light: #a0d3da1e;

$min-bar-height: 4.8rem;

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

html {
  font-size: 14px;
}

.mainbar {
  min-height: $min-bar-height;
  background-color: $darker;
  z-index: 1;

  @include media-breakpoint-up(md) {
    position: sticky;
    top: 0rem;
    z-index: 1040;
  }
}

.mainbarlink {
  margin-left: $spacer;
}

.mainbarlink:global(.active) {
  font-weight: 700;
}

.gray> :not(:first-child) {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.nav {
  top: 4rem;
  background-color: $gray;
}

.main {
  order: 1;
  padding: 2rem 4rem;

  @include media-breakpoint-down(sm) {
    padding: 1rem 0.83rem;
  }

  &>h2:not(:first-child) {
    margin-top: 3rem;
  }

  >h3 {
    margin-top: 1.5rem;
  }

  >ul li,
  >ol li {
    margin-bottom: 0.25rem;
  }

  >table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;

    @include media-breakpoint-down(sm) {
      display: block;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
  }

  @include media-breakpoint-up(lg) {

    >ul,
    >ol,
    >p {
      max-width: 80%;
    }
  }
}

.sidepanel {
  $top: $min-bar-height;

  order: 2;
  position: sticky;
  top: $top;
  height: calc(100vh - #{$top});
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.875rem;
  overflow-y: auto;

  &>ul {
    padding-left: 0;
    border-left: 1px solid $divider;

    & ul {
      padding-left: 1rem;
    }
  }
}

.sidepanel-listitem {
  & a {
    display: block;
    padding: 0.125rem 1.5rem;
    color: transparentize($text, 0.4);

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }
}

.menubutton {
  line-height: 1;
  color: #212529;
}

.sidepanel-forsidenav {
  $top: $min-bar-height;

  background-color: $gray;

  @include media-breakpoint-up(md) {
    position: sticky;
    top: $top;
    z-index: 1000;
    height: calc(100vh - #{$top} - 0.5rem);
    background-color: $gray;
    border-right: 1px solid $divider;
  }
}

.overflow-wrapper {
  @include media-breakpoint-up(md) {
    display: block !important;
    height: 100% !important;
  }
}

.table-of-contents {
  @include media-breakpoint-up(md) {
    height: 100% !important;
    overflow: auto;
    margin-right: -15px;
    padding-right: 15px;
  }
}

.table-of-contents-link {
  &.active {
    margin-top: 1rem;
    font-weight: 500;
    color: $text  !important;
    background: rgba(20, 20, 20, 0.1);

    &+* {
      margin-bottom: 1rem;
    }
  }


  &.active-mainitem {
    margin-top: 1rem;
    color: $text  !important;
    background: rgba(20, 20, 20, 0.1);

    &+* {
      margin-bottom: 1rem;
    }
  }

  &:hover {
    background: rgba(20, 20, 20, 0.1);
  }

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: transparentize($text, 0.25);
    transition: color 0.2s ease-out;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $subtle-on-dark;
  }

  --bs-gutter-x: 0;
}

.table-of-contents-sublink {
  font-size: 90%;
  padding-top: 0.25rem;
  padding-left: 1.5rem;
}

.leftmargin {
  margin-left: $spacer;
}

.toc-link-icon {
  margin-left: -1em;
  margin-bottom: 0.2em;
}

.toc-link-heading {
  margin-left: 1em;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.ou-root-node {
  font-family: sans-serif;
}

.ou-leaf-node {
  font-family: sans-serif;
}

.table thead {
  background-color: $darker;
  color: $white;
}

.nav-link.active {
  font-weight: bold;
}

.nav-link,
.nav-link:hover {
  color: $black;
}

.nav-logout:hover {
  color: rgba(255, 255, 255, .75);
}

.nav-logout {
  color: rgba(255, 255, 255, .55);
  padding-left: 0;
}

.nav-logout-icon {
  display: block;
  margin-top: 0.35rem;
}

.accordion-button {
  background-color: $divider;
}

.grid-page-size {
  width: 5rem;
}

.grid-filter-size {
  width: 20rem;
}

.grid-clickable-row {
  cursor: pointer;
}

.textarea-1 {
  max-height: 2.2rem;
  height: 2.2rem;
}

.textarea-2 {
  max-height: calc(2 * (1.7rem) + 0.5rem);
  height: calc(2 * (1.7rem) + 0.5rem);
}

.textarea-3 {
  max-height: calc(3 * (1.7rem) + 0.5rem);
  height: calc(3 * (1.7rem) + 0.5rem);
}

.textarea-4 {
  max-height: calc(4 * (1.7rem) + 0.5rem);
  height: calc(4 * (1.7rem) + 0.5rem);
}

.textarea-5 {
  max-height: calc(5 * (1.7rem) + 0.5rem);
  height: calc(5 * (1.7rem) + 0.5rem);
}

.textarea-6 {
  max-height: calc(6 * (1.7rem) + 0.5rem);
  height: calc(6 * (1.7rem) + 0.5rem);
}

.toast-message {
  min-height: 3rem;
}

.loading-spinner-height {
  min-height: 3rem;
}

.col-form-label {
  font-weight: 500;
}

.col-form-static {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
}

.dropdown-item:active {
  background-color: $success;
}

.dropdown-menu>.dropdown-item.active {
  background-color: $white;
}

.dropdown-menu>.dropdown-item:hover {
  background-color: $divider
}

.dropdown-menu>.dropdown-item {
  color: $black;
  text-decoration: none;
}

.modal-header-with-alert {
  display: block;
  padding-bottom: 0;
}

.modal-on-modal {
  z-index: 1065;
}

.modal-on-modal-backdrop {
  z-index: 1060;
}

.toc * dd {
  margin-left: 1rem;
}

.section * .figure,
.section>.figure,
.chapter * .figure,
.chapter>.figure {
  display: block;
}

th[align="center"],
.figure>.title {
  text-align: center;
}

.dropdown-menu.show {
  width: max-content;
}

.max-width-33vw {
  max-width: 33vw;
}

.overflowx-clip {
  overflow-x: clip;
  min-width: 0;
}

.rte-editor {
  box-sizing: border-box;
  border: 1px solid $border;
  cursor: text;
}

.rte-inner {
  min-height: 50vh;
  display: flex;
  flex-direction: column-reverse;
}

.rte-editor .narrow {
  min-height: 20vh;
}

.rte-editor .narrow30 {
  min-height: 30vh;
}

.rte-textbox {
  padding: 1rem;
  flex: 1;
}

.rte-toolbar {
  border-bottom: 1px solid $border;
  z-index: 2;
  padding: 0.5rem;
  padding-left: 1em;
  box-sizing: border-box;
}

.rte-toolbar-button {
  background: none;
  border: none;
  float: left;
  width: 2.5rem;
  height: 2.5rem;
}

.rte-toolbar-button:not([disabled]) {
  cursor: pointer;
}

.rte-toolbar-button:not([disabled]):hover,
.rte-toolbar-button:not([disabled]).rte-toolbar-button-active {
  background-color: $gray;
}

.rte-headers {
  margin-top: 0.6rem;
  margin-right: 0.4rem;
  border: none;
  float: left;
}

.rte-headers:not([disabled]) {
  cursor: pointer;
}

.rte-header-1 {
  font-size: 2.5rem;
  font-weight: 500;
}

.rte-header-2 {
  font-size: 2rem;
  font-weight: 500;
}

.rte-header-3 {
  font-size: 1.75rem;
  font-weight: 500;
}

.rte-add-image {
  margin-bottom: 0.1rem;
}

.rte-toolbar-separator {
  float: left;
  border-right: 1px solid $divider;
  height: 1.7rem;
  margin: 0.4rem 0.4em;
}

.rte-inline-editor {
  cursor: text;
  display: flex;
  flex-direction: row;
}

.rte-inline-toolbar {
  display: flex;
  flex-direction: row;
}

.rte-inline-toolbar-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
}

.rte-inline-editor .form-control:focus-within {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
}

.rte-inline-editor .form-control {
  padding-bottom: 0.26rem;
}

.DraftEditor-editorContainer {
  overflow: auto;
  max-height: 600px;
}

.rte-inline-editor .DraftEditor-editorContainer {
  z-index: 0;
}

div.vertical.js-focus-visible {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
}

textarea.form-control {
  overflow: hidden;
}

.memos-threads-body {
  max-height: 66vh;
  min-height: 66vh;
}

.memos-pulsing-circle {
  top: 1px !important;
}

.threads-filter-spinner {
  width: 1rem;
}

.col-xxl-20p {
  @media (min-width: 1400px) {
    flex: 0 0 auto;
    width: 20%;
  }
}

.max-rows-5 {
  resize: vertical;
  max-height: 8rem;
}

.mh-md-33 {
  @media (max-width: 992px) {
    max-height: 33% !important;
  }
}

.mh-md-66 {
  @media (max-width: 992px) {
    max-height: 66% !important;
  }
}

.mh-lg-100 {
  @media (min-width: 992px) {
    max-height: 100% !important;
  }
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 2em !important;
}

.bg-blue-light {
  background-color: $blue-light !important;
}

.bg-purple-light {
  background-color: $purple-light !important;
}

.bg-yellow-light {
  background-color: $yellow-light !important;
}

.bg-teal-light {
  background-color: $teal-light !important;
}

.bg-cyan-light {
  background-color: $cyan-light !important;
}
